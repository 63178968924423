import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquesta espècie presenta uns capells carnosos, grossos de 7 a 12 cm de diàmetre, convexos aplanats. Tenen una superfície llisa i lleugerament viscosa quan hi ha humitat. El color va del crema groguenc a l’avellana més clar al marge. Les làmines són de color argilós o cafè amb llet. Són quasi sempre lliures, atenuades i primes. El peu és cilíndric, robust, de 5 a 12 x 1 a 3 cm, més clar que el capell i presenta petites esquames color terrós distribuïdes per zones. És buit i presenta un petita llengua característica a la part superior. Les espores són groc pàl·lid en massa, finament berrugoses, de 10-15 x 6-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      